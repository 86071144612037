<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Campagna - ' + data.campaign.id" scrollable @hidden="closeForm" @close="closeForm">
      <company ref="company" @update="loadActions()"/>
      <companyworker ref="worker" @update="loadActions()"/>
      <companycontact ref="contact" @update="loadActions()"/>
      <marketingaction ref="marketingaction" @update="loadData(data.campaign.id)"/>
      <mail ref="mail"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Campagna" active>
            <b-row>
              <b-col lg="10">
                <b-form-group label="Campagna">
                  <b-form-input v-model="data.campaign.campaign"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.campaign.enabled" :options="enabledCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.campaign.note" rows="3"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Contatti" v-if="data.campaign.id > 0">
            <b-btn variant="primary" class="mr-2" @click="loadActions()" size="sm">Aggiorna</b-btn>
            <b-btn variant="info" class="mr-2"  @click="prepareActions()" size="sm">Carica</b-btn>
            <b-btn variant="info" @click="prepareSingleAction()" size="sm">Inserisci</b-btn>
            <b-modal v-model="tempActions.show" title="Pianifica" size="lg">
              <b-form-group label="Tipologia">
                <multiselect v-model="tempActions.companiestypesSupport" :options="tempActions.filtersMenu.companiestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.companiestypes = updateCombo(tempActions.companiestypesSupport)"/>
              </b-form-group>
              <b-form-group label="Segnalatore">
                <multiselect v-model="tempActions.suppliersSupport" :options="tempActions.filtersMenu.suppliers" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.suppliers = updateCombo(tempActions.suppliersSupport)"/>
              </b-form-group>
              <b-row>
                <b-col lg="2">
                  <b-form-group label="Tipo contatto">
                    <b-form-select v-model="tempActions.filters.worker" :options="workerCombo" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="4">
                  <b-form-group label="Provincia">
                    <multiselect v-model="tempActions.provincesSupport" :options="tempActions.filtersMenu.provinces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.provinces = updateCombo(tempActions.provincesSupport)"/>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Regioni">
                    <multiselect v-model="tempActions.regionsSupport" :options="tempActions.filtersMenu.regions" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.regions = updateCombo(tempActions.regionsSupport)"/>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Stati">
                    <multiselect v-model="tempActions.statesSupport" :options="tempActions.filtersMenu.states" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.states = updateCombo(tempActions.statesSupport)"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Tags">
                <multiselect v-model="tempActions.tagsSupport" :options="tempActions.filtersMenu.tags" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.tags = updateCombo(tempActions.tagsSupport)"/>
              </b-form-group>
              <b-form-group label="NACE">
                <multiselect v-model="tempActions.nacesSupport" :options="tempActions.filtersMenu.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.naces = updateCombo(tempActions.nacesSupport)"/>
              </b-form-group>
              <template v-slot:modal-footer>
                <div class="w-100">
                  <b-btn variant="primary" @click="saveActions()">Salva</b-btn>
                </div>
              </template>
            </b-modal>
            <b-modal v-model="tempSingleAction.show" title="Pianifica">
              <b-form-group label="ID personale">
                <b-form-input type="number" v-model="tempSingleAction.search.workers_id"/>
              </b-form-group>
              <template v-slot:modal-footer>
                <div class="w-100">
                  <b-btn variant="primary" @click="saveSingleAction()">Salva</b-btn>
                </div>
              </template>
            </b-modal>
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive show-empty mode="remote" class="mt-2" :items="actions.items.data" :fields="actions.fields" :busy="actions.isBusy" :no-local-sorting="true" :sort-by.sync="actions.search.sortby" :sort-desc.sync="actions.search.sortdesc" @sort-changed="sortingChangeActions" @row-clicked="$refs.marketingaction.loadData($event.id)">
              <template v-slot:table-busy>
                <div class="text-center text-primary">
                  <b-spinner class="align-middle"/>
                </div>
              </template>
              <template v-slot:cell(company)="row">
                <b-link @click="$refs.company.loadData(row.item.companies_id)">{{ row.item.company }}</b-link>
              </template>
              <template v-slot:cell(fullname)="row">
                <b-link @click="$refs.worker.loadData(row.item.workers_id, row.item.companies_id)">{{ row.item.fullname }}</b-link>
              </template>
              <template v-slot:cell(contact)="row">
                <b-link @click="$refs.contact.loadData(row.item.contacts_id, row.item.companies_id)">{{ row.item.contact }}</b-link>
              </template>
              <template v-slot:empty>Non ci sono risultati</template>
            </b-table>
            <tablebottom :busy="actions.isBusy" :currentpage="actions.search.page" :total="actions.items.total" @updateCurrentPage="actions.search.page = $event" @loadData="loadActions()"/>
          </b-tab>
          <b-tab title="Mail" v-if="data.campaign.id > 0">
            <b-btn variant="primary" class="mr-2" @click="loadMails()" size="sm">Aggiorna</b-btn>
            <b-btn variant="info" @click="prepareMails()" size="sm">Carica</b-btn>
            <b-modal v-model="tempMails.show" title="Pianifica">
              <b-form-group label="Casella">
                <b-form-select v-model="tempMails.filters.mailboxes_id" :options="tempMails.filtersMenu.mailboxes" value-field="id" text-field="val"/>
              </b-form-group>
              <b-form-group label="Template">
                <b-form-select v-model="tempMails.filters.marketingtemplates_id" :options="tempMails.filtersMenu.marketingtemplates" value-field="id" text-field="val"/>
              </b-form-group>
              <template v-slot:modal-footer>
                <div class="w-100">
                  <b-btn variant="primary" @click="saveMails()">Salva</b-btn>
                </div>
              </template>
            </b-modal>
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive show-empty mode="remote" class="mt-2" :tbody-tr-class="rowClass" :items="mails.items.data" :fields="mails.fields" :busy="mails.isBusy" :no-local-sorting="true" :sort-by.sync="mails.search.sortby" :sort-desc.sync="mails.search.sortdesc" @sort-changed="sortingChangeMails">
              <template v-slot:table-busy>
                <div class="text-center text-primary">
                  <b-spinner class="align-middle"/>
                </div>
              </template>
              <template v-slot:cell(mailboxesmails_id)="row">
                <b-link v-if="row.value > 0" @click="$refs.mail.loadData(row.value)">Mail</b-link>
              </template>
              <template v-slot:empty>Non ci sono risultati</template>
            </b-table>
            <tablebottom :busy="mails.isBusy" :currentpage="mails.search.page" :total="mails.items.total" @updateCurrentPage="mails.search.page = $event" @loadData="loadMails()"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.campaign.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.campaign.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import companyworker from '../companies/companycomponents/companyworker';
import companycontact from '../companies/companycomponents/companycontact';
import marketingaction from './marketingaction';

export default {
  name: "marketingcampaign",
  components: {
    companyworker, companycontact, marketingaction
  },
  data() {
    return {
      show: false,
      data: null,
      actions: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            marketingcampaigns_id: null
          },
          sortby: "company",
          sortdesc: false,
        },
        fields: [{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "fullname",
          label: "Nome",
        },{
          key: 'contact',
          label: "Mail",
          sortable: true
        },{
          key: 'job',
          label: "Mansione"
        },{
          key: "datetime",
          label: "Data e Ora",
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
          sortable: true,
        },{
          key: "status",
          label: "Stato",
          sortable: true,
        }],
        items: {
          total: 0,
          data: []
        }
      },
      tempActions: {
        show: false,
        companiestypesSupport: [],
        suppliersSupport: [],
        provincesSupport: [],
        regionsSupport: [],
        statesSupport: [],
        tagsSupport: [],
        nacesSupport: [],
        filtersMenu: {
          companiestypes: [],
          suppliers: [],
          provinces: [],
          regions: [],
          states: [],
          tags: [],
          naces: [],
        },
        filters: {
          companiestypes: [],
          suppliers: [],
          provinces: [],
          regions: [],
          states: [],
          tags: [],
          naces: [],
          worker: null,
          date: null
        }
      },
      tempSingleAction: {
        show: false,
        search: {
          marketingcampaigns_id: null,
          workers_id: null,
        }
      },
      mails: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            marketingcampaigns_id: null
          },
          sortby: "company",
          sortdesc: false,
        },
        fields: [{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'fullname',
          label: 'Nome',
        },{
          key: 'contact',
          label: "Mail",
          sortable: true
        },{
          key: 'marketingtemplate',
          label: "Template",
        },{
          key: 'mailbox',
          label: "Casella",
        },{
          key: 'contact',
          label: "Mail",
        },{
          key: 'datetime',
          label: "Caricamento",
          sortable: true
        },{
          key: 'success',
          label: "Esito",
          formatter: value => {
            return value === true ? "Inviata" : (value === false ? "Fallita" : "Caricata"); 
          }
        },{
          key: 'mailboxesmails_id',
          label: "Mail",
        }],
        items: {
          total: 0,
          data: []
        }
      },
      tempMails: {
        show: false,
        filtersMenu: {
          mailboxes: [],
          marketingtemplates: []
        },
        filters: {
          mailboxes_id: null,
          marketingtemplates_id: null
        }
      },
      workerCombo: [{
        id: null,
        val: "Tutti"
      },{
        id: true,
        val: "Solo con lavoratore"
      },{
        id: false,
        val: "Solo mail aziendali"
      }],
      enabledCombo: [{
        id: true,
        val: "Attiva"
      },{
        id: false,
        val: "Non attiva"
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    rowsMails() {
      return this.data.mails.length;
    }
  },
  methods: {
    rowClass( item ){
      if ( item !== null ) return item.ping === true ? "table-success" : "";
    },
    loadData(id){
      this.actions.search.filters.marketingcampaigns_id = id;
      this.actions.search.page = 1;
      this.loadActions();
      this.mails.search.filters.marketingcampaigns_id = id;
      this.mails.search.page = 1;
      this.loadMails();
      this.openHttp2("marketing/campaign/", { id: id }).then((result) => {
        console.log(result);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp2("marketing/campaign/save", this.data.campaign).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    deleteData(){
      this.deleteHttp2("marketing/campaign/delete", { id: this.data.campaign.id }).then((result) => {
        if ( result !== null) this.closeForm();
      })
    },
    loadActions(){
      console.log("ciao")
      this.openHttp2("marketing/campaign/actions", this.actions.search).then((result) => {
        this.actions.items = result;
        this.actions.isBusy = false;
      })
    },
    sortingChangeActions(ctx) {
      this.actions.search.sortby = ctx.sortBy;
      this.actions.search.sortdesc = ctx.sortDesc;
      this.loadActions();
    },
    prepareActions(){
      this.openHttp2("marketing/campaign/actions/prepare", null).then((result) => {
        this.tempActions.filtersMenu = result;
        this.tempActions.companiestypesSupport = this.convertCombo(result.companiestypes, []);
        this.tempActions.provincesSupport = this.convertCombo(result.provinces, []);
        this.tempActions.regionsSupport = this.convertCombo(result.regions, []);
        this.tempActions.statesSupport = this.convertCombo(result.states, []);
        this.tempActions.tagsSupport = this.convertCombo(result.tags, []);
        this.tempActions.nacesSupport = this.convertCombo(result.naces, []);
        this.tempActions.filters= {
          companiestypes: [],
          suppliers: [],
          provinces: [],
          regions: [],
          states: [],
          tags: [],
          naces: [],
          mailboxes_id: null,
          marketingtemplates_id: null
        };
        this.tempActions.show = true;
      })
    },
    saveActions(){
      this.tempActions.show = false;
      this.tempActions.filters.id = this.data.campaign.id;
      this.saveHttp2("marketing/campaign/actions/save", this.tempActions.filters).then((result) => {
        if (result !== null) this.loadData(this.data.campaign.id);
      })
    },
    prepareSingleAction(){
      this.tempSingleAction.search.workers_id = null;
      this.tempSingleAction.show = true;
    },
    saveSingleAction(){
      if (this.tempSingleAction.search.workers_id > 0){
        this.tempSingleAction.search.marketingcampaigns_id = this.data.campaign.id;
        this.saveHttp2("marketing/campaign/actions/savesingle", this.tempSingleAction.search).then((result) => {
          if (result !== null) {
            this.tempSingleAction.search.workers_id = null;
            this.tempSingleAction.show = true;          
            this.loadData(this.data.campaign.id);
          }
        })
      }
    },

    loadMails(){
      this.openHttp2("marketing/campaign/mails", this.mails.search).then((result) => {
        console.log(result)
        this.mails.items = result;
        this.mails.isBusy = false;
      })
    },
    sortingChangeMails(ctx) {
      this.mails.search.sortby = ctx.sortBy;
      this.mails.search.sortdesc = ctx.sortDesc;
      this.loadMails();
    },
    prepareMails(){
      this.openHttp2("marketing/campaign/mails/prepare", null).then((result) => {
        this.tempMails.filtersMenu = result;
        this.tempMails.filters = {
          mailboxes_id: null,
          marketingtemplates_id: null
        };
        this.tempMails.show = true;
      })
    },
    saveMails(){
      this.tempMails.show = false;
      this.tempMails.filters.id = this.data.campaign.id;
      this.saveHttp2("marketing/campaign/mails/save", this.tempMails.filters).then((result) => {
        if (result !== null) this.loadData(this.data.campaign.id);
      })
    },




    
    
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>