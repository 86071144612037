<template>
  <div>
    <audit ref="audit" @update="loadData(true)"/>
    <b-modal v-model="showFilter" title="Filtra">
      <b-form-group label="Id">
        <b-form-input type="number" v-model="tempFilter.id"/>
      </b-form-group>
      <b-form-group label="Azienda">
        <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Segnalatore">
        <b-form-select v-model="tempFilter.suppliers_id" :options="addNullCombo(filtersMenu.suppliers)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norma">
        <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="IAF">
        <b-form-select v-model="tempFilter.nacesiafcodes_id" :options="addNullCombo(filtersMenu.iafcodes)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Ente">
        <b-form-select v-model="tempFilter.certificationbodies_id" :options="addNullCombo(filtersMenu.certificationbodies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Autorizzato">
        <b-form-select v-model="tempFilter.users_id" :options="addNullCombo(filtersMenu.users)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Errore">
        <b-form-select v-model="tempFilter.errorstypes_id" :options="addNullCombo(filtersMenu.errorstypes)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet()">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset()">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData()">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen()">Filtra</b-btn>
    <b-card no-body class="mt-2">
      <b-tabs card>
        <b-tab active>
          <template #title>
            Calendar {{ audits1.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="computedData1" :fields="audits1.fields" :busy="audits1.isBusy" :no-local-sorting="true" :sort-by.sync="audits1.search.sortby" :sort-desc.sync="audits1.search.sortdesc" @sort-changed="sortingChange1" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits1.isBusy" :currentpage="audits1.search.page" :total="audits1.items.total" @updateCurrentPage="audits1.search.page = $event" @loadData="loadData1"/>
        </b-tab>
        <b-tab>
          <template #title>
            Planning {{ audits2.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="computedData2" :fields="audits2.fields" :busy="audits2.isBusy" :no-local-sorting="true" :sort-by.sync="audits2.search.sortby" :sort-desc.sync="audits2.search.sortdesc" @sort-changed="sortingChange2" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits2.isBusy" :currentpage="audits2.search.page" :total="audits2.items.total" @updateCurrentPage="audits2.search.page = $event" @loadData="loadData2"/>
        </b-tab>
        <b-tab>
          <template #title>
            Checklist {{ audits3.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="computedData3" :fields="audits3.fields" :busy="audits3.isBusy" :no-local-sorting="true" :sort-by.sync="audits3.search.sortby" :sort-desc.sync="audits3.search.sortdesc" @sort-changed="sortingChange3" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits3.isBusy" :currentpage="audits3.search.page" :total="audits3.items.total" @updateCurrentPage="audits3.search.page = $event" @loadData="loadData3"/>
        </b-tab>
        <b-tab>
          <template #title>
            Veto {{ audits4.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="computedData4" :fields="audits4.fields" :busy="audits4.isBusy" :no-local-sorting="true" :sort-by.sync="audits4.search.sortby" :sort-desc.sync="audits4.search.sortdesc" @sort-changed="sortingChange4" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits4.isBusy" :currentpage="audits4.search.page" :total="audits4.items.total" @updateCurrentPage="audits4.search.page = $event" @loadData="loadData4"/>
        </b-tab>
        <b-tab>
          <template #title>
            Concluded {{ audits5.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="computedData5" :fields="audits5.fields" :busy="audits5.isBusy" :no-local-sorting="true" :sort-by.sync="audits5.search.sortby" :sort-desc.sync="audits5.search.sortdesc" @sort-changed="sortingChange5" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits5.isBusy" :currentpage="audits5.search.page" :total="audits5.items.total" @updateCurrentPage="audits5.search.page = $event" @loadData="loadData5"/>
        </b-tab>
        <b-tab>
          <template #title>
            Failed {{ audits6.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="audits6.items.data" :fields="audits6.fields" :busy="audits6.isBusy" :no-local-sorting="true" :sort-by.sync="audits6.search.sortby" :sort-desc.sync="audits6.search.sortdesc" @sort-changed="sortingChange6" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits6.isBusy" :currentpage="audits6.search.page" :total="audits6.items.total" @updateCurrentPage="audits6.search.page = $event" @loadData="loadData6"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "audits",
  data() {
    return {
      showFilter: false,
      tempFilter: {
        id: null,
        active: true,
        contract: null,
        companies_id: null,
        suppliers_id: null,
        standards_id: null,
        nacesiafcodes_id: null,
        certificationbodies_id: null,
        users_id: null,
        errorstypes_id: null
      },
      filtersMenu: {
        companies:[],
        standards:[],
        iafcodes: [],
        certificationbodies: [],
        errorstypes: []
      },
      audits1: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            auditsstatus_id: 1,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            certificationbodies_id: null,
            users_id: null,
            errorstypes_id: null
          },
          sortby: "plandate",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        },
        fields: [{
          key: 'plandate',
          label: "Piano",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Norme"
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true
        },{
          key: 'expiracy',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'orders_id',
          label: 'Commessa'
        },{
          key: 'errors',
          label: 'Errori',
          sortable: true
        }]
      },
      audits2: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            auditsstatus_id: 2,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            certificationbodies_id: null,
            users_id: null,
            errorstypes_id: null
          },
          sortby: "plandate",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        },
        fields: [{
          key: 'plandate',
          label: 'Piano',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Norme"
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true
        },{
          key: 'expiracy',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'orders_id',
          label: 'Commessa'
        },{
          key: 'plan_percentage',
          label: 'Pianificato',
          sortable: true,
          formatter: value => {
            return value + "%";
          },
        },{
          key: 'percentagepaid',
          label: 'Pagamento',
          sortable: true,
          formatter: value => {
            if (value == null) return "No";
            return value + "%";
          }
        },{
          key: 'usersauth',
          label: 'Autorizzati',
        },{
          key: 'usersplan',
          label: 'Piano',
        },{
          key: 'maxordersdaysdate',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'errors',
          label: 'Errori',
          sortable: true
        }],
      },
      audits3: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            auditsstatus_id: 3,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            certificationbodies_id: null,
            users_id: null,
            errorstypes_id: null
          },
          sortby: "plandate",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        },
        fields: [{
          key: 'plandate',
          label: 'Piano',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Norme"
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true
        },{
          key: 'expiracy',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'orders_id',
          label: 'Commessa'
        },{
          key: 'plan_percentage',
          label: 'Pianificato',
          sortable: true,
          formatter: value => {
            return value + "%";
          },
        },{
          key: 'check_percentage',
          label: 'Checklist',
          sortable: true,
          formatter: value => {
            if (value == null) return "No";
            return value + "%";
          }
        },{
          key: 'percentagepaid',
          label: 'Pagamento',
          sortable: true,
          formatter: value => {
            if (value == null) return "No";
            return value + "%";
          }
        },{
          key: 'usersauth',
          label: 'Autorizzati',
        },{
          key: 'usersplan',
          label: 'Piano',
        },{
          key: 'maxordersdaysdate',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'errors',
          label: 'Errori',
          sortable: true
        }],
      },
      audits4: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            auditsstatus_id: 4,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            certificationbodies_id: null,
            users_id: null,
            errorstypes_id: null
          },
          sortby: "plandate",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        },
        fields: [{
          key: 'plandate',
          label: 'Piano',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Norme"
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true
        },{
          key: 'expiracy',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'orders_id',
          label: 'Commessa'
        },{
          key: 'plan_percentage',
          label: 'Pianificato',
          sortable: true,
          formatter: value => {
            return value + "%";
          }
        },{
          key: 'check_percentage',
          label: 'Checklist',
          sortable: true,
          formatter: value => {
            return value + "%";
          }
        },{
          key: 'veto_percentage',
          label: "Veto",
          sortable: true,
          formatter: value => {
            return value + "%";
          }
        },{
          key: 'percentagepaid',
          label: 'Pagamento',
          sortable: true,
          formatter: value => {
            if (value == null) return "No";
            return value + "%";
          }
        },{
          key: 'usersauth',
          label: 'Autorizzati',
        },{
          key: 'usersplan',
          label: 'Piano',
        },{
          key: 'maxordersdaysdate',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'errors',
          label: 'Errori',
          sortable: true
        }],
      },
      audits5: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            auditsstatus_id: 5,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            certificationbodies_id: null,
            users_id: null,
            errorstypes_id: null
          },
          sortby: "plandate",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        },
        fields: [{
          key: 'plandate',
          label: 'Piano',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Norme"
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true
        },{
          key: 'expiracy',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'orders_id',
          label: 'Commessa'
        },{
          key: 'plan_percentage',
          label: 'Pianificato',
          sortable: true,
          formatter: value => {
            return value + "%";
          }
        },{
          key: 'check_percentage',
          label: 'Checklist',
          sortable: true,
          formatter: value => {
            return value + "%";
          }
        },{
          key: 'veto_percentage',
          label: "Veto",
          sortable: true,
          formatter: value => {
            return value + "%";
          }
        },{
          key: 'percentagepaid',
          label: 'Pagamento',
          sortable: true,
          formatter: value => {
            console.log(value)


            if (value == null) return "No";
            return value + "%";
          }
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'errors',
          label: 'Errori',
          sortable: true
        }],
      },
      audits6: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            auditsstatus_id: 2,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            certificationbodies_id: null,
            users_id: null,
            errorstypes_id: null
          },
          sortby: "plandate",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        },
        fields: [{
          key: 'plandate',
          label: 'Piano',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Norme"
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true
        },{
          key: 'expiracy',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'orders_id',
          label: 'Commessa'
        },{
          key: 'percentagepaid',
          label: 'Pagamento',
          sortable: true,
          formatter: value => {
            if (value == null) return "No";
            return value + "%";
          }
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'errors',
          label: 'Errori',
          sortable: true
        }],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    computedData1(){
      return this.audits1.items.data.map(function( item ) {
        let today = new Date();
        let planDate = new Date(item.plandate);
        let expiracyDate = new Date(item.expiracy);
        item._cellVariants = {
          plandate: today.getTime() >= planDate.getTime() ? "danger" : "",
          expiracy: today.getTime() >= expiracyDate.getTime() ? "danger" : "",
          orders_id: item.orders_id === null ? "danger" : "",
          errors: item.errors > 0 ? "danger" : "",
        };
        return item;
      });
    },
    computedData2(){
      return this.audits2.items.data.map(function( item ) {
        let today = new Date();
        let expiracyDate = new Date(item.expiracy);
        item._cellVariants = {
          expiracy: today.getTime() >= expiracyDate.getTime() ? "danger" : "",
          orders_id: item.orders_id === null ? "danger" : "",
          errors: item.errors > 0 ? "danger" : "",
          plan_percentage: item.plan_percentage === 0 ? 'danger' : (item.plan_percentage === 100 ? 'success' : 'warning'),
          percentagepaid: item.percentagepaid === 0 ? 'danger' : (item.percentagepaid === 100 ? 'success' : 'warning'),
        };
        return item;
      });
    },
    computedData3(){
      return this.audits3.items.data.map(function( item ) {
        let today = new Date();
        let expiracyDate = new Date(item.expiracy);
        item._cellVariants = {
          expiracy: today.getTime() >= expiracyDate.getTime() ? "danger" : "",
          orders_id: item.orders_id === null ? "danger" : "",
          errors: item.errors > 0 ? "danger" : "",
          plan_percentage: item.plan_percentage === 0 ? 'danger' : (item.plan_percentage === 100 ? 'success' : 'warning'),
          percentagepaid: item.percentagepaid === 0 ? 'danger' : (item.percentagepaid === 100 ? 'success' : 'warning'),
          check_percentage: item.check_percentage === 0 ? 'danger' : (item.check_percentage === 100 ? 'success' : 'warning'),
        };
        return item;
      });
    },
    computedData4(){
      return this.audits4.items.data.map(function( item ) {
        let today = new Date();
        let expiracyDate = new Date(item.expiracy);
        item._cellVariants = {
          expiracy: today.getTime() >= expiracyDate.getTime() ? "danger" : "",
          orders_id: item.orders_id === null ? "danger" : "",
          errors: item.errors > 0 ? "danger" : "",
          plan_percentage: item.plan_percentage === 0 ? 'danger' : (item.plan_percentage === 100 ? 'success' : 'warning'),
          percentagepaid: item.percentagepaid === 0 ? 'danger' : (item.percentagepaid === 100 ? 'success' : 'warning'),
          check_percentage: item.check_percentage === 0 ? 'danger' : (item.check_percentage === 100 ? 'success' : 'warning'),
          veto_percentage: item.veto_percentage === 0 ? 'danger' : (item.veto_percentage === 100 ? 'success' : 'warning'),
        };
        return item;
      });
    },
    computedData5(){
      return this.audits5.items.data.map(function( item ) {
        item._cellVariants = {
          orders_id: item.orders_id === null ? "danger" : "",
          errors: item.errors > 0 ? "danger" : "",
          plan_percentage: item.plan_percentage === 0 ? 'danger' : (item.plan_percentage === 100 ? 'success' : 'warning'),
          percentagepaid: item.percentagepaid === 0 ? 'danger' : (item.percentagepaid === 100 ? 'success' : 'warning'),
          check_percentage: item.check_percentage === 0 ? 'danger' : (item.check_percentage === 100 ? 'success' : 'warning'),
          veto_percentage: item.veto_percentage === 0 ? 'danger' : (item.veto_percentage === 100 ? 'success' : 'warning'),
        };
        return item;
      });
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadData1();
      this.loadData2();
      this.loadData3();
      this.loadData4();
      this.loadData5();
      this.loadData6();
    },
    loadData1(){
      this.audits1.isBusy = true;
      this.openHttp2("audits/", this.audits1.search).then((result) => {
        this.audits1.items = result;
        this.audits1.isBusy = false;
      })
    },
    loadData2(){
      this.audits2.isBusy = true;
      this.openHttp2("audits/", this.audits2.search).then((result) => {
        this.audits2.items = result;
        this.audits2.isBusy = false;
      })
    },
    loadData3(){
      this.audits3.isBusy = true;
      this.openHttp2("audits/", this.audits3.search).then((result) => {
        this.audits3.items = result;
        this.audits3.isBusy = false;
      })
    },
    loadData4(){
      this.audits4.isBusy = true;
      this.openHttp2("audits/", this.audits4.search).then((result) => {
        this.audits4.items = result;
        this.audits4.isBusy = false;
      })
    },
    loadData5(){
      this.audits5.isBusy = true;
      this.openHttp2("audits/", this.audits5.search).then((result) => {
        console.log(result)
        this.audits5.items = result;
        this.audits5.isBusy = false;
      })
    },
    loadData6(){
      this.audits6.isBusy = true;
      this.openHttp2("audits/", this.audits6.search).then((result) => {
        this.audits6.items = result;
        this.audits6.isBusy = false;
      })
    },
    filterOpen(){
      this.openHttp2("audits/filters", null).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.audits1.search.page = 1;
      this.audits1.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits1.search.filters.auditsstatus_id = 1;

      this.audits2.search.page = 1;
      this.audits2.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits2.search.filters.auditsstatus_id = 2;

      this.audits3.search.page = 1;
      this.audits3.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits3.search.filters.auditsstatus_id = 3;

      this.audits4.search.page = 1;
      this.audits4.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits4.search.filters.auditsstatus_id = 4;

      this.audits5.search.page = 1;
      this.audits5.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits5.search.filters.auditsstatus_id = 5;

      this.audits6.search.page = 1;
      this.audits6.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits6.search.filters.auditsstatus_id = 6;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        id: null,
        active: true,
        contract: null,
        companies_id: null,
        suppliers_id: null,
        standards_id: null,
        nacesiafcodes_id: null,
        certificationbodies_id: null,
        users_id: null,
        errorstypes_id: null
      };
      this.filterSet();
    },
    sortingChange1(ctx) {
      this.audits1.search.sortby = ctx.sortBy;
      this.audits1.search.sortdesc = ctx.sortDesc;
      this.loadData1();
    },
    sortingChange2(ctx) {
      this.audits2.search.sortby = ctx.sortBy;
      this.audits2.search.sortdesc = ctx.sortDesc;
      this.loadData2();
    },
    sortingChange3(ctx) {
      this.audits3.search.sortby = ctx.sortBy;
      this.audits3.search.sortdesc = ctx.sortDesc;
      this.loadData3();
    },
    sortingChange4(ctx) {
      this.audits4.search.sortby = ctx.sortBy;
      this.audits4.search.sortdesc = ctx.sortDesc;
      this.loadData4();
    },
    sortingChange5(ctx) {
      this.audits5.search.sortby = ctx.sortBy;
      this.audits5.search.sortdesc = ctx.sortDesc;
      this.loadData5();
    },
    sortingChange6(ctx) {
      this.audits6.search.sortby = ctx.sortBy;
      this.audits6.search.sortdesc = ctx.sortDesc;
      this.loadData6();
    }
  }
}
</script>