<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Allegato - ' + data.attachment.id" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Nome file">
                <b-form-input v-model="data.attachment.filename"/>
            </b-form-group>
            <b-form-group label="File">
                <b-form-file @change="fileChange"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.attachment.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
        <form method="post" action="/api2/marketing/template/attachment/download" ref="downloadFormSubmit" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" ref="downloadFormId" name="id">
        </form>
    </div>
</template>

<script>
export default {
    name: "marketingtemplateattachment",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        token(){
            return this.$store.getters.getToken;
        },
    },
    methods: {
        loadData( marketingtemplatesId, id ){
            this.openHttp2("marketing/template/attachment", { marketingtemplates_id: marketingtemplatesId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        fileChange(event){
            var reader = new FileReader();
            if (event.target.files != null){
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = ()=> {
                    this.data.attachment.filename = event.target.files[0].name.toLowerCase();
                    this.data.attachment.file = reader.result;
                }
            } else {
                reader.readAsDataURL(event.dataTransfer.files[0]);
                reader.onload = ()=> {
                    this.data.attachment.filename = event.dataTransfer.files[0].name.toLowerCase();
                    this.data.attachment.file = reader.result;
                }
            }
        },
        downloadData(id){
            this.$refs.downloadFormId.value = id;
            this.$refs.downloadFormSubmit.submit();
        },
        saveData(){
            this.saveHttp2("marketing/template/attachment/save", this.data.attachment).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp2("marketing/template/attachment/delete", { id: this.data.attachment.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>